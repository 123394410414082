import { standardBorderRadius } from "utils/variables";
import styled from "styled-components";

export const SuggestionContainer = styled.div`
  color: #0c5460;
  background-color: #d1ecf1;
  font-size: 18px;
  padding: 10px 20px;
  margin-bottom: 20px;
  border-radius: ${standardBorderRadius};
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    align-items: start;
  }

  svg {
    margin-right: 10px;
  }
`;
