import { standardBorderRadius } from "utils/variables";
import styled from "styled-components";

export const CompanyGiftText = styled.span`
  font-weight: 400;
  font-size: 22px;

  @media only screen and (max-width: 550px) {
    font-size: 20px;
  }
`;

export const CompanyGiftContainer = styled.div`
  margin-top: 10px;
  display: flex;
  margin-bottom: 24px;

  /* @supports (gap: 10px) {
    gap: 0 16px;
  } */
`;

export const RadioContainer = styled.div`
  input:focus-visible {
    outline: solid #333;
  }

  /* @supports not (gap: 10px) { */
  &:not(:last-child) {
    margin-right: 16px;
  }
  /* } */
`;

export const RadioLabel = styled.label`
  font-size: 18px;
  margin-left: 5px;
`;

export const CallCenterEmailDisclaimer = styled.p`
  color: #0c5460;
  background-color: #d1ecf1;
  font-size: 20px;
  padding: 15px;
  border-radius: ${standardBorderRadius};
  border: 1px solid #0c5460;
  margin-bottom: 20px;
  text-align: center;
`;

export const CCEmailText = styled.p`
  font-size: 18px;
  margin-bottom: 10px;
`;
