import React, { ReactElement } from "react";
import { Navigate } from "react-router";
import { selectIsPageError } from "redux/helpers/helpers.selector";
import { useAppSelector } from "utils/hooks";

interface Props {
  children: ReactElement | null;
}

const ProtectedRoute: React.FC<Props> = ({ children }) => {
  const isPageError = useAppSelector(selectIsPageError);

  // istanbul ignore if
  if (!isPageError)
    return <Navigate to={`/${window.location.search}`} replace />;

  return children;
};

export default ProtectedRoute;
