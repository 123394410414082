import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import englishTranslation from "locales/en/translation";
import frenchTranslation from "locales/fr/translation";

const resources = {
  en: englishTranslation,
  fr: frenchTranslation,
};

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  debug: process.env.REACT_APP_NODE_ENV === "prd" ? false : true,
  lng: "en",
  resources,
});

export default i18n;
