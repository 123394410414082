import styled from "styled-components";

interface Props {
  isFormDisabled: boolean;
}

export const AppContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: ${(props: Props) =>
    props.isFormDisabled ? "none;" : "auto"};
`;
