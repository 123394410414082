import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  HeaderContainer,
  Logo,
  LanguageButton,
  ButtonsContainer,
} from "./header.styles";

import { useAppDispatch, useAppSelector } from "utils/hooks";
import { selectFormLanguage } from "redux/form/form.selector";
import { updateFormLanguage } from "redux/form/form.reducer";

const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const formLanguage = useAppSelector(selectFormLanguage);
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(formLanguage);
  }, [formLanguage]); // eslint-disable-line

  return (
    <HeaderContainer id="header">
      {formLanguage === "en" ? (
        <a
          className="logo-link"
          href={process.env.REACT_APP_MAIN_WEBSITE_URL_EN}
          rel="noreferrer"
          target="_blank"
        >
          <Logo src={process.env.REACT_APP_HEADER_LOGO_EN} alt="Logo" />
        </a>
      ) : (
        <a
          href={process.env.REACT_APP_MAIN_WEBSITE_URL_FR}
          rel="noreferrer"
          target="_blank"
        >
          <Logo src={process.env.REACT_APP_HEADER_LOGO_FR} alt="Logo" />
        </a>
      )}
      <ButtonsContainer data-cy="languageButtons">
        <LanguageButton
          currentLang={formLanguage === "en"}
          onClick={() => {
            dispatch(updateFormLanguage("en"));
          }}
        >
          EN
        </LanguageButton>{" "}
        /{" "}
        <LanguageButton
          currentLang={formLanguage === "fr"}
          onClick={() => {
            dispatch(updateFormLanguage("fr"));
          }}
        >
          FR
        </LanguageButton>
      </ButtonsContainer>
    </HeaderContainer>
  );
};

export default Header;
