import { FC } from "react";
import { useIsDesktop } from "utils/helper-functions";
import { RightImage } from "./contextual-image.styles";
import { selectContextualImage } from "redux/presValue/presValue.selector";
import { useAppSelector } from "utils/hooks";
import { selectFormLanguage } from "redux/form/form.selector";
import { useTranslation } from "react-i18next";

export const ContextualImage: FC = () => {
  const contextualImage = useAppSelector(selectContextualImage);
  const formLanguage = useAppSelector(selectFormLanguage);
  const isDesktop = useIsDesktop();
  const { t } = useTranslation();

  return (
    <RightImage
      src={
        contextualImage[formLanguage][
          isDesktop ? "contextualImageDT" : "contextualImageM"
        ]
      }
      alt={t("Heart and Stroke Researcher")}
    />
  );
};

export default ContextualImage;
