import React, { useEffect } from "react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { selectCurrentForm } from "redux/helpers/helpers.selector";
import { NavigationContainer } from "./navigation.styles";
import NavigationButton from "components/navigation-button/navigation-button.component";
import { useAppSelector } from "utils/hooks";
import analyticsAPI from "utils/analyticsAPI";
import { buttonsCollection } from "utils/variables";

interface Props {
  isContextual: boolean;
}

const Navigation: React.FC<Props> = ({ isContextual }) => {
  const currentForm = useAppSelector(selectCurrentForm);
  const appInsights = useAppInsightsContext();
  const pageEngagementStartTime = Date.now();
  const lastForm = currentForm;
  const componentName =
    lastForm === 0 ? "DonationType" : lastForm === 1 ? "DonorInfo" : "Payment";

  useEffect(() => {
    analyticsAPI.handleTrackPageView(appInsights, {
      name: componentName,
      pageEngagementStartTime,
    });
    return () => {
      const engagementTimeSeconds =
        (Date.now() - pageEngagementStartTime) / 1000;
      const metricData = {
        average: Math.round(engagementTimeSeconds * 100) / 100,
        name: "React Component Engaged Time (seconds)",
        sampleCount: 1,
      };
      const additionalProperties = { "Component Name": componentName };
      appInsights.trackMetric(metricData, additionalProperties);
    };
  }, [currentForm]); // eslint-disable-line

  return (
    <NavigationContainer
      role="navigation"
      isContextual={isContextual}
      data-cy="navigation"
      id="navigation"
    >
      {buttonsCollection.map((button, index) => (
        <NavigationButton
          key={index}
          buttonText={button.buttonText}
          Icon={button.Icon}
          buttonID={index}
        />
      ))}
    </NavigationContainer>
  );
};

export default Navigation;
