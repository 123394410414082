import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AskAmounts, ContextualImage, PresValueState } from "utils/interfaces";

const initialState: PresValueState = {
  contextualImage: {
    en: {
      contextualImageDT: process.env.REACT_APP_DEFAULT_HERO_IMAGE_DT_EN,
      contextualImageM: process.env.REACT_APP_DEFAULT_HERO_IMAGE_M_EN,
    },
    fr: {
      contextualImageDT: process.env.REACT_APP_DEFAULT_HERO_IMAGE_DT_EN,
      contextualImageM: process.env.REACT_APP_DEFAULT_HERO_IMAGE_M_EN,
    },
  },
  isHonourSupported: true,
  isMemorySupported: true,
  isMonthlySupported: true,
  isOneTimeSupported: true,
  askAmounts: {
    "one-time": [25, 50, 100],
    monthly: [25, 50, 100],
  },
  presid: 1,
};

const presValueSlice = createSlice({
  name: "presValue",
  initialState,
  reducers: {
    updateContextualImage(
      state,
      action: PayloadAction<{ en: ContextualImage; fr: ContextualImage }>,
    ) {
      state.contextualImage = action.payload;
    },
    updateIsHonourSupported(state, action: PayloadAction<boolean>) {
      state.isHonourSupported = action.payload;
    },
    updateIsMemorySupported(state, action: PayloadAction<boolean>) {
      state.isMemorySupported = action.payload;
    },
    updateIsOneTimeSupported(state, action: PayloadAction<boolean>) {
      state.isOneTimeSupported = action.payload;
    },
    updateIsMonthlySupported(state, action: PayloadAction<boolean>) {
      state.isMonthlySupported = action.payload;
    },
    updateAskAmounts(state, action: PayloadAction<AskAmounts>) {
      state.askAmounts = { ...action.payload };
    },
    updateOneTimeAsk(state, action: PayloadAction<number[]>) {
      state.askAmounts["one-time"] = action.payload;
    },
    updateMonthlyAsk(state, action: PayloadAction<number[]>) {
      state.askAmounts["monthly"] = action.payload;
    },
    updatePresid(state, action: PayloadAction<number>) {
      state.presid = action.payload;
    },
  },
});

export const {
  updateContextualImage,
  updateIsHonourSupported,
  updateIsMemorySupported,
  updateIsMonthlySupported,
  updateIsOneTimeSupported,
  updateAskAmounts,
  updatePresid,
  updateOneTimeAsk,
  updateMonthlyAsk,
} = presValueSlice.actions;

export default presValueSlice.reducer;
