import { configureStore } from "@reduxjs/toolkit";
import formReducer from "./form/form.reducer";
import presValueReducer from "./presValue/presValue.reducer";
import helpersReducer from "./helpers/helpers.reducer";

export const store = configureStore({
  reducer: {
    form: formReducer,
    presValue: presValueReducer,
    helpers: helpersReducer,
  },
  devTools: process.env.REACT_APP_NODE_ENV === "prd" ? false : true,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
