import styled from "styled-components";

interface Props {
  currentLang?: boolean;
}

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  background-color: #000;
  align-items: center;
  padding: 23px 34px;
  flex: 0 1 auto;
  height: 72.5px;

  .logo-link:focus-visible {
    outline: solid #fff;
  }

  @media only screen and (max-width: 320px) {
    padding: 13px 14px;
  }
`;

export const Logo = styled.img`
  width: 250px;
  height: 100%;
  display: block;

  @media only screen and (max-width: 450px) {
    width: 150px;
  }
`;

export const ButtonsContainer = styled.div`
  color: #fff;
`;

export const LanguageButton = styled.button`
  background-color: transparent;
  color: #fff;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-size: 20px;
  ${(props: Props) =>
    props.currentLang ? "font-weight: 700;" : "font-weight: 400; opacity: .5;"}

  &:focus-visible {
    outline: solid #fff;
  }

  @media only screen and (max-width: 450px) {
    font-size: 16px;
  }
`;
