import styled from "styled-components";

export const FinalMessage = styled.p`
  font-size: 20px;
  margin-bottom: 20px;

  span {
    text-decoration: underline;
    font-weight: 700;
  }
`;

export const CallCenterRecordingText = styled.p`
  background-color: #ffff73;
  text-transform: uppercase;
  color: #333;
  text-align: center;
  width: 100%;
  padding: 10px;
  font-weight: 700;
  font-size: 19px;
  margin-top: 15px;
`;
