import styled from "styled-components";
import { primaryColor } from "../../utils/variables";

interface Props {
  isContextual?: boolean;
  isVisa?: boolean;
}

export const RightBlockContainer = styled.div`
  width: 50%;
  position: relative;

  ${(props: Props) =>
    props.isContextual ? "display: block;" : "display: none"};

  @media only screen and (max-width: 1080px) {
    width: 100%;
    height: 480px;
  }
`;

export const InformationalBlurbContainer = styled.div`
  text-align: center;
  font-size: 20px;
  padding: 40px 20px 30px 20px;
  font-weight: 700;
  display: ${(props: Props) => (props.isVisa ? "flex" : "block")};
  justify-content: center;
  align-items: center;

  div {
    margin-right: 30px;

    p:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }

  @media only screen and (max-width: 785px) {
    flex-direction: column;
    padding: 20px 20px 30px 20px;
    font-size: 16px;

    div {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  @media only screen and (max-width: 400px) {
    font-size: 14px;
  }

  .red {
    color: ${primaryColor};
  }

  img {
    max-width: 100%;
    width: auto;
  }
`;

export const CampaignMemoContainer = styled.div`
  background-color: ${primaryColor};
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 22px 25px;
  color: #fff;
  width: 94%;
  font-family: "Arial", sans-serif;

  .memo-heading {
    font-size: 32px;
    font-weight: 700;

    @media only screen and (max-width: 550px) {
      font-size: 21px;
    }

    @media only screen and (max-width: 320px) {
      font-size: 16px;
    }
  }

  .memo-text {
    font-size: 20px;

    @media only screen and (max-width: 550px) {
      font-size: 16px;
    }

    @media only screen and (max-width: 320px) {
      font-size: 14px;
    }
  }

  .hide-on-mobile {
    @media only screen and (max-width: 1080px) {
      display: none;
    }
  }

  .hide-on-desktop {
    @media only screen and (min-width: 1081px) {
      display: none;
    }
  }
`;

export const MemoHeading = styled.h2`
  font-size: 32px;
  margin-bottom: 22px;

  @media only screen and (max-width: 550px) {
    font-size: 21px;
    margin-bottom: 16px;
  }
`;

export const MemoText = styled.p`
  font-size: 20px;

  @media only screen and (max-width: 550px) {
    font-size: 16px;
  }
`;

export const LeftBlockContainer = styled.div<Props>`
  width: ${(props: Props) => (props.isContextual ? "50%;" : "100%;")};
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1080px) {
    width: 100%;
  }
`;
