import styled from "styled-components";

interface Props {
  isContextual: boolean;
}

export const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  ${(props: Props) => !props.isContextual && "padding: 0 20%;"}

  @media only screen and (max-width: 930px) {
    ${(props: Props) => !props.isContextual && "padding: 0 10%;"}
  }

  @media only screen and (max-width: 690px) {
    padding: 0;
  }

  @media only screen and (max-width: 500px) {
    align-items: end;
  }
`;
