import React from "react";
import { selectFormLanguage } from "redux/form/form.selector";
import { selectMonthlySuggestionText } from "redux/helpers/helpers.selector";
import { useAppSelector } from "utils/hooks";

import { SuggestionContainer } from "./monthly-suggestion.styles";
import { HandHoldingHeart } from "icons/hand-holding-heart/hand-holding-heart";

const MonthlySuggestion: React.FC = () => {
  const monthlySuggestionText = useAppSelector(selectMonthlySuggestionText);
  const formLanguage = useAppSelector(selectFormLanguage);

  return (
    <SuggestionContainer data-cy="monthlySuggestion">
      <div>
        <HandHoldingHeart color="#0c5460" />
      </div>
      {monthlySuggestionText[formLanguage]}
    </SuggestionContainer>
  );
};

export default MonthlySuggestion;
