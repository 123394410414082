import { FC } from "react";
import { Select as StyledSelect } from "global";
import { SelectOptionItem } from "utils/interfaces";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
  id: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void;
  value: string;
  optionsData: SelectOptionItem[];
  isError?: string;
  withTranslation?: boolean;
  withValueTranslation?: boolean;
}

export const Select: FC<Props> = ({
  optionsData,
  withTranslation,
  withValueTranslation,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <StyledSelect {...props}>
      {optionsData.map((item) => (
        <option
          key={item.value}
          value={withValueTranslation ? t(item.value) : item.value}
        >
          {withTranslation ? t(item.name) : item.name}
        </option>
      ))}
    </StyledSelect>
  );
};

export default Select;
