import styled from "styled-components";

export const BackButtonContainer = styled.button`
  color: #000;
  text-decoration: underline;
  border: none;
  background-color: transparent;
  font-size: 19px;
  cursor: pointer;

  &:focus-visible {
    outline: solid #333;
  }
`;
