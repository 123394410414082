import styled from "styled-components";
import { primaryColor, standardBorderRadius } from "../../utils/variables";

interface Props {
  isActive?: boolean;
  isError?: boolean;
}

export const Overlay = styled.div`
  height: 100%;
  width: 100%;
  background-color: #333;
  opacity: 0.7;
  position: fixed;
  top: 0;
  left: 0;
`;

export const PopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: white;
  transform: translate(-50%, -50%);
  padding: 25px;
  border: ${(props: Props) =>
    props.isError ? `3px solid ${primaryColor};` : "none;"};

  @media only screen and (max-width: 690px) {
    width: 90%;
  }
`;

export const CloseButton = styled.button`
  cursor: pointer;
  background-color: ${primaryColor};
  color: #fff;
  display: inline-block;
  border: none;
  border-radius: ${standardBorderRadius};
  padding: 17px 20px;
  margin-top: 20px;
`;

export const PopupMessage = styled.p`
  font-size: 22px;
  font-weight: 700;
`;
