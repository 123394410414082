import React from "react";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Input, InputContainer, RedStar } from "global";
import { updateDebitAccountDetails } from "redux/form/form.reducer";
import {
  selectDebitAccountDetails,
  selectFormErrors,
} from "redux/form/form.selector";
import { useAppDispatch, useAppSelector } from "utils/hooks";

const DebitForm: React.FC = () => {
  // GLOBAL STATE
  const debitAccountDetails = useAppSelector(selectDebitAccountDetails);
  const formErrors = useAppSelector(selectFormErrors);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const regex = /^\d*$/g;
    if (regex.test(value))
      dispatch(
        updateDebitAccountDetails({ ...debitAccountDetails, [name]: value }),
      );
  };

  return (
    <div>
      <InputContainer>
        <label htmlFor="institutionNumber">
          <RedStar>*</RedStar> {t("Institution number")}
        </label>
        <Input
          id="institutionNumber"
          name="institutionNumber"
          type="text"
          placeholder="000"
          maxLength={3}
          onChange={handleInput}
          value={debitAccountDetails.institutionNumber}
          isError={formErrors.institutionNumber}
        />
        {formErrors.institutionNumber && (
          <ErrorMessage>
            <span>!</span> {t(formErrors.institutionNumber)}
          </ErrorMessage>
        )}
      </InputContainer>
      <InputContainer>
        <label htmlFor="transitNumber">
          <RedStar>*</RedStar> {t("Transit number")}
        </label>
        <Input
          id="transitNumber"
          name="transitNumber"
          type="text"
          placeholder="11000"
          maxLength={5}
          onChange={handleInput}
          value={debitAccountDetails.transitNumber}
          isError={formErrors.transitNumber}
        />
        {formErrors.transitNumber && (
          <ErrorMessage>
            <span>!</span> {t(formErrors.transitNumber)}
          </ErrorMessage>
        )}
      </InputContainer>
      <InputContainer>
        <label htmlFor="accountNumber">
          <RedStar>*</RedStar> {t("Account number")}
        </label>
        <Input
          id="accountNumber"
          name="accountNumber"
          type="text"
          placeholder="000123456789"
          maxLength={12}
          onChange={handleInput}
          value={debitAccountDetails.accountNumber}
          isError={formErrors.accountNumber}
        />
        {formErrors.accountNumber && (
          <ErrorMessage>
            <span>!</span> {t(formErrors.accountNumber)}
          </ErrorMessage>
        )}
      </InputContainer>
      <InputContainer>
        <label htmlFor="verifyAccountNumber">
          <RedStar>*</RedStar> {t("Verify account number")}
        </label>
        <Input
          id="verifyAccountNumber"
          name="verifyAccountNumber"
          type="text"
          placeholder="000123456789"
          maxLength={12}
          onChange={handleInput}
          value={debitAccountDetails.verifyAccountNumber}
          isError={formErrors.verifyAccountNumber}
        />
        {formErrors.verifyAccountNumber && (
          <ErrorMessage>
            <span>!</span> {t(formErrors.verifyAccountNumber)}
          </ErrorMessage>
        )}
      </InputContainer>
    </div>
  );
};

export default DebitForm;
