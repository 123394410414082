import styled from "styled-components";
import { outlineColor, primaryColor } from "../../utils/variables";

interface Props {
  isCurrentOrPastForm: boolean;
}

export const ButtonContainer = styled.button`
  width: 33.33333%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  padding: 5px 0;
  background-color: transparent;
  border: none;
  border-bottom: ${(props: Props) =>
    props.isCurrentOrPastForm
      ? `2px solid ${primaryColor}`
      : "2px solid transparent;"};
  cursor: pointer;

  span {
    text-transform: uppercase;
    color: ${(props: Props) =>
      props.isCurrentOrPastForm ? primaryColor : "#000"};
    text-align: center;

    @media only screen and (max-width: 320px) {
      font-size: 12px;
    }
  }

  &:hover {
    color: ${primaryColor};
    span {
      color: ${primaryColor};
    }
  }

  &:focus-visible {
    outline: solid ${outlineColor} 3px;
  }
`;
