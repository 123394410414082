import React from "react";
import { useTranslation } from "react-i18next";

import {
  FooterContainer,
  FooterCredentials,
  FooterLogo,
  FirstRowContainer,
  AddressContainer,
  CharitiesContainer,
  AccreditationLogo,
  FooterLink,
  CampaignSpecificContent,
} from "./footer.styles";

import { useAppSelector } from "utils/hooks";
import { selectFormLanguage } from "redux/form/form.selector";
import {
  selectFooterHTML,
  selectFormType,
} from "redux/helpers/helpers.selector";

const Footer: React.FC = () => {
  const formLanguage = useAppSelector(selectFormLanguage);
  const footerHTML = useAppSelector(selectFooterHTML);
  const formType = useAppSelector(selectFormType);

  const { t } = useTranslation();

  return (
    <FooterContainer>
      <FirstRowContainer>
        <AddressContainer>{t("Address")}</AddressContainer>
        <CharitiesContainer>
          <AccreditationLogo
            src={process.env.REACT_APP_ACCREDITATION}
            alt="Strong Charities Accreditation"
          />
          <div style={{ display: "flex" }}>
            <FooterLink
              target="_blank"
              href={process.env.REACT_APP_PRIVACY_POLICY_URL}
            >
              {t("Privacy policy")}
            </FooterLink>
            <FooterLink
              target="_blank"
              href={process.env.REACT_APP_TERMS_OF_USE_URL}
            >
              {t("Terms of use")}
            </FooterLink>
          </div>
        </CharitiesContainer>
      </FirstRowContainer>
      {/* Additional footer text for campaigns id supported */}
      {footerHTML.en && footerHTML.fr ? (
        <CampaignSpecificContent
          data-cy="campaignSpecificFooter"
          dangerouslySetInnerHTML={{
            __html: `${footerHTML[formLanguage]}<br/>`,
          }}
          isContextual={formType === "cont"}
        ></CampaignSpecificContent>
      ) : formLanguage === "en" ? (
        <FooterLogo
          src={process.env.REACT_APP_FOOTER_LOGO_EN}
          alt="Every Beat Counts"
        />
      ) : (
        <FooterLogo
          src={process.env.REACT_APP_FOOTER_LOGO_FR}
          alt="Chaque battement compte"
        />
      )}
      <FooterCredentials
        dangerouslySetInnerHTML={{
          __html: t("Footer Text", { year: new Date().getFullYear() }),
        }}
      ></FooterCredentials>
    </FooterContainer>
  );
};

export default Footer;
