import styled from "styled-components";

interface Props {
  isContextual: boolean;
}

export const FormContainer = styled.form`
  ${(props: Props) => !props.isContextual && "padding: 0 20%;"}
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: 930px) {
    ${(props: Props) => !props.isContextual && "padding: 0 10%;"}
  }

  @media only screen and (max-width: 690px) {
    ${(props: Props) => !props.isContextual && "padding: 0"}
  }
`;
