import React from "react";

import DonationType from "components/donation-type/donation-type.component";
import DonorInfo from "components/donor-info/donor-info.component";
import Payment from "components/payment/payment.component";
import { FormContainer } from "./form.styles";

interface Props {
  isContextual: boolean;
}

const Form: React.FC<Props> = ({ isContextual }) => {
  return (
    <FormContainer isContextual={isContextual}>
      <DonationType />
      <DonorInfo />
      <Payment />
    </FormContainer>
  );
};

export default Form;
