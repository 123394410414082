import {
  outlineColor,
  primaryColor,
  standardBorderRadius,
} from "utils/variables";
import styled from "styled-components";

export const ThankYouTextContainer = styled.div`
  padding: 15% 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: 1080px) {
    padding: 20px;
  }
`;

export const ThankYouText = styled.p`
  font-size: 20px;
  color: #333;
  font-weight: 700;
`;

export const TogetherText = styled.p`
  color: #000;
  font-size: 24px;
  font-weight: 700;
  margin: 30px 0;

  span {
    color: ${primaryColor};
  }

  @media only screen and (max-width: 450px) {
    margin: 15px 0;
  }
`;

export const ThankYouParagraph = styled.p`
  font-size: 20px;
  color: #333;
  margin-bottom: 20px;
  font-weight: 400;
`;

export const SeeImpactLink = styled.a`
  text-decoration: none;
  color: white;
  background-color: ${primaryColor};
  display: block;
  width: 100%;
  padding: 17px 20px;
  font-size: 19px;
  font-weight: 700;
  border-radius: ${standardBorderRadius};
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &:focus-visible {
    outline: solid ${outlineColor} 3px;
  }
`;

export const EmailLink = styled.a`
  color: ${primaryColor};
  text-decoration: none;
  font-weight: 700;

  &:focus-visible {
    outline: solid ${outlineColor} 3px;
  }
`;

export const ThankYouButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  /* @supports (gap: 10px) {
    gap: 20px;
  } */
`;

export const DoctorNameBox = styled.div`
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  width: 90%;
  padding: 15px 30px;
  text-align: center;
  background-color: ${primaryColor};
  font-size: 20px;
  color: #fff;
`;

export const ConfirmationNumber = styled.p`
  font-size: 20px;
  color: #333;
  font-weight: 400;
  margin-top: 20px;
`;

export const BannerContainer = styled.div`
  /* padding: 20px; */
  margin-top: 80px;

  img {
    width: 100%;
  }
`;
