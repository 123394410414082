import styled, { createGlobalStyle } from "styled-components";
import {
  lightGrey,
  outlineColor,
  primaryColor,
  standardBorderRadius,
} from "utils/variables";

interface Props {
  formID?: number;
  currentForm?: number;
}

interface PageParams {
  viewportHeight: number;
}

export default createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        outline:0;
        box-sizing:border-box;
        font-family: 'Arial', sans-serif; 
    }
    #root{
        margin:0 auto;
        height: 100%;
        white-space: pre-line;
    }
    html,
    body {
        height: 100%;
        margin: 0;
        @media only screen and (min-width: 1080px) {
          overflow: hidden;
        }    
    }

    h2{
        font-weight: 700;
        font-size: 32px;
    }
`;

export const PageContainer = styled.div`
  display: flex;
  height: ${(props: PageParams) => `${props.viewportHeight - 72.5}px`};
  position: relative;

  @media only screen and (max-width: 1080px) {
    flex-direction: column-reverse;
    height: auto;
  }
`;

export const SectionHeading = styled.h2`
  margin-bottom: 12px;
  font-size: 27px;

  @media only screen and (max-width: 550px) {
    font-size: 21px;
  }
`;

export const FormPartContainer = styled.section<Props>`
  transition: transform 1s;
  padding: 36px 30px;
  transform: ${({ formID = 0, currentForm = 0 }) =>
    `translateX(${(formID - currentForm) * 100}%)`};
  ${(props) =>
    props.currentForm === props.formID
      ? "opacity: 1; position: static; visibility: visible;"
      : "opacity: 0; position: absolute; visibility: hidden;"};

  @media only screen and (max-width: 550px) {
    padding: 18px 8px;
  }
`;

export const RequiredText = styled.span`
  color: ${primaryColor};
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 23px;
  display: inline-block;

  @media only screen and (max-width: 550px) {
    font-size: 16px;
  }
`;

export const InputRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  /* Support for gap in modern browsers */
  @supports (gap: 10px) {
    gap: 0 38px;
  }

  @media only screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 25px;

  & label {
    margin-bottom: 6px;
    font-size: 22px;

    @media only screen and (max-width: 550px) {
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 550px) {
    width: 100%;
  }
`;

export const RedStar = styled.span`
  color: ${primaryColor};
`;

export const Input = styled.input<{ isError?: string }>`
  height: 41px;
  padding: 0 7px;
  font-size: 20px;
  ${(props) => props.isError && `border: 1px solid ${primaryColor}`};

  &:focus {
    outline: solid ${outlineColor} 2px;
  }

  @media only screen and (max-width: 550px) {
    font-size: 18px;
  }
`;

export const TextArea = styled.textarea<{ isError?: string }>`
  width: 100%;
  padding: 7px;
  font-size: 20px;
  min-height: 150px;
  ${(props) => props.isError && `border: 1px solid ${primaryColor}`};

  &:focus {
    outline: solid ${outlineColor} 2px;
  }
`;

export const Select = styled.select<{ isError?: string }>`
  height: 41px;
  padding: 0 7px;
  font-size: 20px;

  &:focus {
    outline: solid ${outlineColor} 2px;
  }

  ${(props) => props.isError && `border: 1px solid ${primaryColor}`};
`;

export const CheckboxContainer = styled.div`
  display: flex;
  /* margin-bottom: 30px; */

  & input:focus-visible {
    outline: solid #333;
  }

  & label {
    margin-left: 8px;
    font-size: 18px;

    @media only screen and (max-width: 550px) {
      font-size: 16px;
    }

    @media only screen and (max-width: 320px) {
      font-size: 12px;
    }
  }
`;

export const ErrorMessage = styled.p`
  color: ${primaryColor};
  font-size: 20px;
  margin-top: 18px;

  span {
    border: 1px solid ${primaryColor};
    border-radius: 50%;
    width: 23px;
    text-align: center;
    display: inline-block;
  }

  @media only screen and (max-width: 550px) {
    font-size: 18px;
  }
`;

export const NextStepButton = styled.button`
  font-size: 19px;
  color: #fff;
  background-color: ${primaryColor};
  border: none;
  padding: 19px 37px;
  cursor: pointer;
  border-radius: ${standardBorderRadius};
  margin-left: auto;
  text-align: right;

  &:focus-visible {
    outline: solid #333 3px;
  }

  &:disabled {
    cursor: default;
    background: ${lightGrey};
  }

  @media only screen and (max-width: 550px) {
    font-size: 17px;
  }

  @media only screen and (max-width: 320px) {
    font-size: 15px;
    padding: 17px 31px;
  }
`;

export const BottomButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

export const TextAreaCharLimit = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 8px;

  @supports (gap: 10px) {
    gap: 3px;
  }
`;

export const RegularParagraph = styled.p`
  font-size: 20px;

  a {
    color: ${primaryColor};
    font-weight: 700;
  }
`;
