import styled from "styled-components";

interface Props {
  isContextual: boolean;
}

export const FooterContainer = styled.footer`
  background-color: #000;
  padding: 40px 34px;
  flex: 1;

  @media only screen and (max-width: 550px) {
    padding: 40px 15px;
  }
`;

export const FirstRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 550px) {
    flex-direction: column;

    @supports (gap: 10px) {
      gap: 30px 0;
    }
  }
`;

export const AddressContainer = styled.p`
  color: #fff;
  font-size: 16px;
  text-align: left;

  @media only screen and (max-width: 550px) {
    text-align: center;

    @supports not (gap: 10px) {
      margin-bottom: 30px;
    }
  }
`;

export const CharitiesContainer = styled.div``;

export const AccreditationLogo = styled.img`
  display: block;
  width: 110px;
  height: 110px;
  margin: 0 auto;
  margin-bottom: 14px;
`;

export const FooterLink = styled.a`
  color: #fff;
  font-size: 11px;
  text-align: center;

  &:focus-visible {
    outline: solid #fff;
  }

  &:not(:last-of-type) {
    margin-right: 21px;
  }
`;

export const FooterLogo = styled.img`
  display: block;
  margin: 20px auto;
  max-width: 100%;

  @media only screen and (max-width: 450px) {
    width: 100%;
  }
`;

export const FooterCredentials = styled.p`
  text-align: center;
  color: #fff;
  font-size: 12px;

  sup {
    font-size: 7px;
  }
`;

export const CampaignSpecificContent = styled.div`
  color: #fff;
  font-size: 12px;
  text-align: center;
  width: ${(props: Props) => (props.isContextual ? "100%" : "60%")};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  img {
    display: block;
    max-width: 100%;
  }

  @media only screen and (max-width: 550px) {
    width: 100%;
  }
`;
