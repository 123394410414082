import styled from "styled-components";
import {
  outlineColor,
  primaryColor,
  standardBorderRadius,
  standardBorderWidth,
} from "../../utils/variables";

interface TypeProps {
  isSelected: boolean;
}

export const DonationTypeSection = styled.div`
  margin-bottom: 35px;
`;

export const TypeButtonsContainer = styled.div`
  display: flex;
`;

export const TypeButton = styled.button`
  width: 100%;
  color: ${primaryColor};
  transition:
    background-color 0.4s,
    color 0.4s;
  cursor: pointer;
  border: ${standardBorderWidth} solid ${primaryColor};
  background-color: #fff;
  border-radius: ${standardBorderRadius};
  padding: 17px 0;
  font-size: 19px;
  font-weight: 700;
  ${(props: TypeProps) =>
    props.isSelected && `background-color: ${primaryColor}; color: #fff;`}

  &:focus-visible {
    outline: solid ${outlineColor} 3px;
  }

  &:hover {
    background-color: ${primaryColor};
    color: #fff;
  }

  &:not(:last-child) {
    margin-right: 45px;

    @media only screen and (max-width: 500px) {
      margin-right: 20px;
    }

    @media only screen and (max-width: 320px) {
      margin-right: 10px;
    }
  }

  @media only screen and (max-width: 550px) {
    font-size: 17px;
  }
`;

export const AmountSection = styled.div`
  margin-bottom: 35px;
`;

export const AmountButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  /* Support for gap in modern browsers */
  @supports (gap: 10px) {
    gap: 20px 24px;

    @media only screen and (max-width: 550px) {
      gap: 20px 0;
    }
  }

  align-items: center;
  flex-wrap: wrap;
`;

export const AmountButton = styled.button`
  color: ${primaryColor};
  transition:
    background-color 0.4s,
    color 0.4s;
  cursor: pointer;
  border: ${standardBorderWidth} solid ${primaryColor};
  background-color: #fff;
  border-radius: ${standardBorderRadius};
  padding: 17px 17px;
  font-size: 24px;
  font-weight: 700;

  &:focus-visible {
    outline: solid ${outlineColor} 3px;
  }

  ${(props: TypeProps) =>
    props.isSelected && `background-color: ${primaryColor}; color: #fff;`}

  &:hover {
    background-color: ${primaryColor};
    color: #fff;
  }

  @media only screen and (max-width: 1306px) {
    width: 30%;
  }

  @media only screen and (max-width: 550px) {
    padding: 17px 0;
    font-size: 20px;
  }
`;

export const OtherInputContainer = styled.div`
  flex: 1;
  display: flex;
  min-width: 300px;
  border-radius: ${standardBorderRadius};

  input {
    border-radius: 0 ${standardBorderRadius} ${standardBorderRadius} 0;
    border-right: 2px solid ${primaryColor};
    border-top: 2px solid ${primaryColor};
    border-bottom: 2px solid ${primaryColor};
    border-left: 0;
    width: 100%;
    padding: 0 18px;
    font-size: 24px;
    font-weight: 700;
    color: ${primaryColor};
  }

  &:focus-within {
    outline: solid ${outlineColor} 3px;
  }

  @media only screen and (max-width: 550px) {
    width: 100%;
  }

  @media only screen and (max-width: 320px) {
    min-width: 100%;
  }
`;

export const OtherLabel = styled.label`
  font-size: 19px;
  font-weight: 700;
  border: ${standardBorderWidth} solid ${primaryColor};
  display: inline-block;
  height: 100%;
  color: ${primaryColor};
  padding: 20px;
  border-radius: ${standardBorderRadius} 0 0 ${standardBorderRadius};
  cursor: pointer;

  ${(props: TypeProps) =>
    props.isSelected && `background-color: ${primaryColor}; color: #fff;`}

  @media only screen and (max-width: 550px) {
    font-size: 17px;
  }
`;

export const CoverFeeContainer = styled.div`
  margin: 20px 0;
`;

export const CoverFeeText = styled.p`
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;

  @media only screen and (max-width: 320px) {
    font-size: 14px;
  }
`;

export const HorizontalLine = styled.hr`
  margin-bottom: 20px;
`;

export const CCReportTributeLink = styled.a`
  color: ${primaryColor};
  transition:
    background-color 0.4s,
    color 0.4s;
  cursor: pointer;
  border: ${standardBorderWidth} solid ${primaryColor};
  background-color: #fff;
  border-radius: ${standardBorderRadius};
  padding: 17px 25px;
  font-size: 19px;
  font-weight: 700;
  display: inline-block;
  text-decoration: none;
  margin-bottom: 20px;
  width: 46.5%;
  text-align: center;

  &:focus-visible {
    outline: solid ${outlineColor} 3px;
  }

  &:hover {
    background-color: ${primaryColor};
    color: #fff;
  }
`;
