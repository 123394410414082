import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

const selectForm = (state: RootState) => state.form;

export const selectDonationType = createSelector(
  [selectForm],
  (form) => form.donationInfo.donationType,
);

export const selectDonationAmount = createSelector(
  [selectForm],
  (form) => form.donationInfo.donationAmount,
);

export const selectDonorInfo = createSelector(
  [selectForm],
  (form) => form.donorInfo,
);

export const selectDonationInfo = createSelector(
  [selectForm],
  (form) => form.donationInfo,
);

export const selectHonourType = createSelector(
  [selectDonationInfo],
  (donationInfo) => donationInfo.honourType,
);

export const selectHonoureeName = createSelector(
  [selectDonationInfo],
  (donationInfo) => donationInfo.honoureeName,
);

export const selectFormErrors = createSelector(
  [selectForm],
  (form) => form.formErrors,
);

export const selectIsTributeGift = createSelector(
  [selectDonationInfo],
  (donationInfo) => donationInfo.isTributeGift,
);

export const selectIsCompanyGift = createSelector(
  [selectDonationInfo],
  (donationInfo) => donationInfo.isCompanyGift,
);

export const selectFormLanguage = createSelector(
  [selectDonationInfo],
  (donationInfo) => donationInfo.formLanguage,
);

export const selectHonourCardType = createSelector(
  [selectDonationInfo],
  (donationInfo) => donationInfo.honourCardType,
);

export const selectRecipientEmail = createSelector(
  [selectDonationInfo],
  (donationInfo) => donationInfo.recipientEmail,
);

export const selectEmailSubject = createSelector(
  [selectDonationInfo],
  (donationInfo) => donationInfo.emailSubject,
);

export const selectPersonalMessage = createSelector(
  [selectDonationInfo],
  (donationInfo) => donationInfo.personalMessage,
);

export const selectIsDuplicateEcard = createSelector(
  [selectDonationInfo],
  (donationInfo) => donationInfo.isDuplicateEcard,
);

export const selectCardActive = createSelector(
  [selectDonationInfo],
  (donationInfo) => donationInfo.cardActive,
);

export const selectCoverTransactionFee = createSelector(
  [selectDonationInfo],
  (donationInfo) => donationInfo.coverTransactionFee,
);

export const selectPaymentMethod = createSelector(
  [selectDonationInfo],
  (donationInfo) => donationInfo.paymentMethod,
);

export const selectHonoureeAddressLine = createSelector(
  [selectDonationInfo],
  (donationInfo) => donationInfo.honoureeAddressLine,
);

export const selectHonoureeCity = createSelector(
  [selectDonationInfo],
  (donationInfo) => donationInfo.honoureeCity,
);

export const selectHonoureeCountry = createSelector(
  [selectDonationInfo],
  (donationInfo) => donationInfo.honoureeCountry,
);

export const selectHonoureePostal = createSelector(
  [selectDonationInfo],
  (donationInfo) => donationInfo.honoureePostal,
);

export const selectHonoureeProvinceState = createSelector(
  [selectDonationInfo],
  (donationInfo) => donationInfo.honoureeProvinceState,
);

export const selectSource = createSelector(
  [selectDonationInfo],
  (donationInfo) => donationInfo.source,
);

export const selectCardsCollection = createSelector(
  [selectDonationInfo],
  (donationInfo) => donationInfo.cardsCollection,
);

export const selectLoyaltyCardNumber = createSelector(
  [selectDonationInfo],
  (donationInfo) => donationInfo.loyaltyCardNumber,
);

export const selectCardRecipientTitle = createSelector(
  [selectDonationInfo],
  (donationInfo) => donationInfo.cardRecipientTitle,
);

export const selectCardRecipientFullName = createSelector(
  [selectDonationInfo],
  (donationInfo) => donationInfo.cardRecipientFullName,
);

export const selectAppealCode = createSelector(
  [selectDonationInfo],
  (donationInfo) => donationInfo.appealCode,
);

export const selectIsPrintReceipt = createSelector(
  [selectDonationInfo],
  (donationInfo) => donationInfo.isPrintReceipt,
);

export const selectCallCentreNumber = createSelector(
  [selectDonationInfo],
  (donationInfo) => donationInfo.callCentreNumber,
);

export const selectDebitAccountDetails = createSelector(
  [selectForm],
  (form) => form.debitAccountDetails,
);
