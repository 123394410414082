import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "components/footer/footer.component";
import {
  FormPartContainer,
  PageContainer,
  RegularParagraph,
  SectionHeading,
} from "global";
import { selectFormLanguage } from "redux/form/form.selector";
import { useAppSelector } from "utils/hooks";
import {
  LeftBlockContainer,
  RightBlockContainer,
} from "pages/donation/donation-page.styles";
import { BannerContainer } from "pages/thank-you/thank-you.styles";
import ContextualImage from "components/contextual-image/contextual-image.component";

interface Props {
  viewportHeight: number;
}

const ErrorPage: React.FC<Props> = ({ viewportHeight }) => {
  const formLanguage = useAppSelector(selectFormLanguage);
  const { t } = useTranslation();

  return (
    <PageContainer viewportHeight={viewportHeight}>
      <LeftBlockContainer id="left-section" isContextual={true}>
        <main>
          <FormPartContainer>
            <SectionHeading>{t("We have run into issues")}</SectionHeading>
            <RegularParagraph>{t("Technical difficulties")}</RegularParagraph>

            <br />
            <RegularParagraph>
              {t("Call center agents")}
              <a href="tel:+1-877-882-2582">+1-877-882-2582</a>.
            </RegularParagraph>

            <BannerContainer>
              <a
                href={
                  formLanguage === "en"
                    ? process.env.REACT_APP_THANK_YOU_PAGE_BANNER_LINK_EN
                    : process.env.REACT_APP_THANK_YOU_PAGE_BANNER_LINK_FR
                }
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={
                    formLanguage === "en"
                      ? process.env.REACT_APP_THANK_YOU_PAGE_BANNER_EN
                      : process.env.REACT_APP_THANK_YOU_PAGE_BANNER_FR
                  }
                  alt="Ride For Heart Banner"
                />
              </a>
            </BannerContainer>
          </FormPartContainer>
        </main>
        <Footer />
      </LeftBlockContainer>
      <RightBlockContainer isContextual={true}>
        <ContextualImage />
      </RightBlockContainer>
    </PageContainer>
  );
};

export default ErrorPage;
