import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

const selectPresValue = (state: RootState) => state.presValue;

export const selectContextualImage = createSelector(
  [selectPresValue],
  (presValue) => presValue.contextualImage,
);

export const selectIsMemorySupported = createSelector(
  [selectPresValue],
  (presValue) => presValue.isMemorySupported,
);

export const selectIsHonourSupported = createSelector(
  [selectPresValue],
  (presValue) => presValue.isHonourSupported,
);

export const selectIsOneTimeSupported = createSelector(
  [selectPresValue],
  (presValue) => presValue.isOneTimeSupported,
);

export const selectIsMonthlySupported = createSelector(
  [selectPresValue],
  (presValue) => presValue.isMonthlySupported,
);

export const selectAskAmounts = createSelector(
  [selectPresValue],
  (presValue) => presValue.askAmounts,
);

export const selectPresId = createSelector(
  [selectPresValue],
  (presValue) => presValue.presid,
);
