import React from "react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useTranslation } from "react-i18next";
import { updateCurrentForm } from "redux/helpers/helpers.reducer";
import { selectCurrentForm } from "redux/helpers/helpers.selector";
import { useAppDispatch, useAppSelector } from "utils/hooks";

import { BackButtonContainer } from "./back-button.styles";
import analyticsAPI from "utils/analyticsAPI";
import { scrollToTheTop } from "utils/helper-functions";

const BackButton: React.FC = () => {
  // GLOBAL STATE
  const currentForm = useAppSelector(selectCurrentForm);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const appInsights = useAppInsightsContext();

  const handleBackButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    analyticsAPI.handleBackButton(appInsights, { currentForm });
    dispatch(updateCurrentForm(currentForm - 1));
    scrollToTheTop();
  };

  return (
    <BackButtonContainer onClick={handleBackButtonClick}>
      &#8826; {t("Back")}
    </BackButtonContainer>
  );
};

export default BackButton;
