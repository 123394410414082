import { FC } from "react";
import { IconProps } from "utils/interfaces";

export const Heart: FC<IconProps> = ({ color }) => {
  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.8848 17.1711C55.0732 15.292 53.903 13.5891 52.4396 12.1579C50.9752 10.7223 49.2486 9.58153 47.3537 8.7975C45.3888 7.98129 43.2814 7.5635 41.1537 7.5684C38.1688 7.5684 35.2564 8.38578 32.7256 9.92973C32.1201 10.2991 31.5449 10.7047 31 11.1467C30.4551 10.7047 29.8799 10.2991 29.2744 9.92973C26.7436 8.38578 23.8312 7.5684 20.8463 7.5684C18.6969 7.5684 16.6141 7.98012 14.6463 8.7975C12.7451 9.58461 11.0316 10.7168 9.56035 12.1579C8.0951 13.5875 6.92461 15.2908 6.11523 17.1711C5.27363 19.1268 4.84375 21.2036 4.84375 23.3409C4.84375 25.3571 5.25547 27.458 6.07285 29.5954C6.75703 31.3815 7.73789 33.2342 8.99121 35.1051C10.9771 38.0659 13.7078 41.1538 17.0984 44.284C22.7172 49.4729 28.2814 53.0573 28.5176 53.2026L29.9525 54.1229C30.5883 54.5286 31.4057 54.5286 32.0414 54.1229L33.4764 53.2026C33.7125 53.0512 39.2707 49.4729 44.8955 44.284C48.2861 41.1538 51.0168 38.0659 53.0027 35.1051C54.2561 33.2342 55.243 31.3815 55.9211 29.5954C56.7385 27.458 57.1502 25.3571 57.1502 23.3409C57.1563 21.2036 56.7264 19.1268 55.8848 17.1711V17.1711Z"
        fill={color}
      />
    </svg>
  );
};
