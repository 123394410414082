const englishTranslation = {
  translation: {
    "Your gift": "your gift",
    "Donor info": "donor info",
    Payment: "payment",
    "Beat as one": "Beat as one.",
    "Help Rally":
      "Help rally the best scientific minds around critical heart and brain issues, many on the verge of significant breakthroughs. All with one goal: Beat heart disease and stroke.",
    "Give now": "Please give now",
    Address:
      "Heart and Stroke Foundation of Canada\n1200-2300 Yonge St, Box 2414\nToronto, ON M4P1E4\n1-888-473-4636\ndonorinfo@heartandstroke.ca\nMonday to Friday: 9am - 8pm\nSaturday: 9am - 5pm",
    "Privacy policy": "Privacy Policy",
    "Terms of use": "Terms of Use",
    "Heart and Stroke Researcher": "Heart and Stroke Researcher",

    // Donation type
    "What type of gift": "What type of gift would you like to give?",
    "Choose gift amount": "Choose your gift amount",
    "one-time": "One-time",
    monthly: "Monthly",
    "in-honour": "In Honour",
    "in-memory": "In Memory",
    "Honour Gift Type": "Honour Gift Type",
    "Honouree Name": "Honouree Name",
    "Tribute Card Type": "Tribute Card Type",
    "Your personal message": "Your personal message",
    "Card will be blank": " (card will be blank, if not completed)",
    "No Card": "No Card",
    "Send an eCard": "Send an eCard",
    "Mail a card": "Mail a card on my behalf",
    "Duplicate ecard checkbox": "Yes, send me a copy of eCard.",
    Other: "Other",
    "Next Step": "Next Step",
    "In Honour Gift Checkbox": "Yes, this is an In Honour or In Memory gift.",
    "Recipient's Email": "Recipient Email Address",
    "eCard Subject": "eCard Subject",
    "Full Name": "Full Name",
    "Select card": "Please select one of the cards",
    "Footer Text":
      "Heart and Stroke Foundation of Canada Charitable Registration Number: 10684-6942-RR0001\n\nHeart and Stroke Foundation of New Brunswick Charitable Registration Number: 11924-6940-RR0001\n\n\u2122The heart and / Icon on its own and the heart and / Icon followed by another icon or words in English or French are trademarks of the Heart and Stroke Foundation of Canada.\n\n© {{year}} Heart and Stroke Foundation of Canada. All rights reserved.",
    "Honouree Street Address": "Street Address",
    "Honouree City": "City",
    "Honouree State / Province": "Province / State",
    "Honouree Postal": "Postal / Zip Code",
    "Honouree Country": "Country",
    "Default in-memory ecard message":
      "Deepest condolences during this difficult time.",
    "Default tribute subject": "A donation has been made in tribute of",

    // Donor Info
    "Provide your info": "Donor Information",
    "Required info": "Required Information",
    "Company gift": "Company gift",
    "Company Name": "Company Name",
    Title: "Title",
    "First Name": "First Name",
    "Last Name": "Last Name",
    "Street Address": "Street Address",
    City: "City",
    "State / Province": "Province / State",
    Postal: "Postal / Zip Code",
    Country: "Country",
    "Address 2": "Address 2",
    "Phone Number": "Phone Number",
    "Email Address": "Email Address",
    "Send Monthly Tips":
      "Yes! Please send me monthly health tips, research updates and more.",
    Back: "Back",

    // Thank you page
    "thank you for gift": "thank you for your generous gift",
    "Together, we'll": "Together, we'll",
    beat: "beat",
    "heart disease and stroke": "heart disease and stroke.",
    "thank you for supporting breakthrough":
      "Thank you for supporting breakthrough research that continues to advance the prevention, diagnosis, and treatment of heart disease and stroke.",
    "tax receipt":
      "You will receive your official tax receipt shortly in your inbox. If you have any questions or concerns please contact us at",
    "See the impact": "See the impact of your gift",
    "Start a new donation": "Start a new donation",
    "Your confirmation number": "Your confirmation number is:",
    "TY researcher name": "Dr. {{researcherName}}, Heart & Stroke Researcher",

    // Error messsages
    "Select gift type": "Please select the gift type",
    "Select gift amount": "Please select the gift amount",
    "Enter correct amount": "Please enter correct amount",
    "Enter honouree name": "Please enter honouree name",
    "Select honour gift type": "Please select honour gift type",
    "Enter company name": "Please enter company name",
    "Enter first name": "Please enter first name",
    "Enter last name": "Please enter last name",
    "Enter street address": "Please enter street address",
    "Enter postal": "Please enter postal",
    "Enter city": "Please enter city",
    "Select State / Province": "Please select State / Province",
    "Select State / Province Input": "Please enter State / Province",
    "Select country": "Please select country",
    "Enter email": "Please provide email",
    "Provide valid email": "Please provide a valid email",
    "Provide valid email or select print":
      "Please provide a valid email or select print receipt",
    "Enter recipient email": "Please enter recipient email",
    "Enter full name": "Please enter full name",
    "Card recipient info": "Card recipient information",
    "Provide ecard subject": "Please provide ecard subject",
    "Provide personal message": "Please provide personal message",
    "More than 5": "Minimum $5 donation required",
    "First name greater than 1": "First name length should be greater than 1",
    "First name less than 50": "First name length should be less than 50",
    "Last name greater than 1": "Last name length should be greater than 1",
    "Last name less than 50": "Last name length should be less than 100",
    "Loyalty card equal 9": "Please provide a 9 digit number",
    "Invalid phone number": "Please enter valid phone number",
    "Enter Call Centre Call Number": "Please Enter Call Centre Call Number",
    "Institution number 3 digits": "Institution number must be 3 digits",
    "Transit number 5 digits": "Transit number must be 5 digits",
    "Account number 5 digits": "Account number must be at least 5 digits",
    "Verified account number 5 digits":
      "Verified account number must be at least 5 digits",
    "Account numbers do not match": "Account numbers do not match",

    // Payment info
    "Complete payment info": "Complete payment information",
    "Complete Donation": "Complete Donation",
    "You will be making a": "You will be making a",
    "donation of": "donation of",
    "Agree to charge credit card":
      "By checking this option, I agree to use my credit card as a payment method and authorize this organization to charge my credit card account to fulfill my donation commitment.",
    "Agree to cover fee": "I want to cover the fees for my donation ($2.00).",
    "Did you know you can cover fees":
      "Did you know that by helping offset the processing fee, we’ll be able to do more with your donation?",
    Loading: "Loading...",
    "Credit Card": "Credit Card",
    "Bank Withdrawal": "Bank Withdrawal",
    "Your monthly gift process":
      "Your future monthly gifts will be processed on the {{date}}{{afterNumber}} of each month, starting {{fullDate}}.",
    "One time terms":
      "By completing this step, you allow the Heart and Stroke Foundation of Canada to use your card information to fulfill your donation.",
    "Monthly terms card":
      "By checking this option, I agree to use my credit card as a payment method and authorize this organization to charge my credit card account to fulfill my donation commitment.",
    "Monthly terms debit":
      "By checking this option, I agree to use my bank account as a payment method and authorize this organization to debit my bank account to fulfill my donation commitment.",
    "starting today": ", starting today.",

    // Debit Form
    "Institution number": "Institution number (3 numeric characters)",
    "Transit number": "Transit number (5 numeric characters)",
    "Account number": "Account number (5-12 numeric characters)",
    "Verify account number": "Verify account number (5-12 numeric characters)",

    // Provinces
    Alberta: "Alberta",
    "British Columbia": "British Columbia",
    Manitoba: "Manitoba",
    "New Brunswick": "New Brunswick",
    Newfoundland: "Newfoundland and Labrador",
    "Nova Scotia": "Nova Scotia",
    Northwest: "Northwest Territories",
    Nunavut: "Nunavut",
    Ontario: "Ontario",
    "Prince Edward": "Prince Edward Island",
    Quebec: "Quebec",
    Saskatchewan: "Saskatchewan",
    Yukon: "Yukon",

    // Aeroplan
    "Are you aeroplan": "Are you an Aeroplan member?",
    "Get points":
      "Get 4 Aeroplan points for every $1 you donate to the Heart and Stroke Foundation!*",
    "9-digit number": "(9-digit number)",
    "Aeroplan Footer":
      "\n*Offer available until January 31, 2023. Earn 4 Aeroplan points for every $1 donated through this donation page to the Heart and Stroke Foundation, up to a maximum of 2,500 bonus points per transaction for CRA regulation compliance. Please allow up to 6-8 weeks for points to be posted to your account.",

    // Popup
    "Something went wrong": "Something went wrong. Please try again",
    "Please correct your donation information":
      "Please correct your donation information, as indicated on the form before proceeding.",
    "Welcome back":
      "Welcome back{{firstName}}. Thanks for your support. We have prefilled some of the form information for you.",
    "Incorrect card": "Please provide correct credit card details.",
    "Technical difficulties":
      "We are experiencing some technical difficulties. Please try again later.",
    "Agree to terms": "Please agree to the terms in order to continue",
    "Process request": "Please wait as we process your request",
    "Session timeout": "Your session has timed out. Please reload the page",
    "Session time left": "Your session is ending in {{sessionTimeLeft}}",
    "Stay connected": "Stay connected",
    Reload: "Reload",
    "Can't process request":
      "Sorry, we can not process request at the moment. Please try again later",

    // Call Center
    "Recording on": "Turn on recording",
    "Recording off": "Turn off recording",
    "Receipts blurb":
      "We offer receipts by email as this is the fastest and more efficient delivery method. Please provide your email address so we can send your receipt.",
    "Print receipt requested": "Print receipt requested",
    "Contact Centre Call Number": "Contact Centre Call Number",
    "Tribute Report": "Tribute Report Request",
    "Valid email address":
      "Please enter a valid email address in order to receive your official tax receipt.",

    // Error page
    "We have run into issues": "Apologies, we have run into some issues.",
    "Call center agents":
      "You can also contact our Call Center agents, they would help process your donation by phone. Call us at ",

    // Titles
    Mr: "Mr.",
    Ms: "Ms.",
    Mrs: "Mrs.",
    Miss: "Miss",
    Dr: "Dr.",

    // Booleans
    Yes: "Yes",
    No: "No",
  },
};

export default englishTranslation;
