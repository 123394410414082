import ReactDOM from "react-dom/client";
import App from "App";
import { BrowserRouter } from "react-router-dom";
import "i18n";

import { Provider } from "react-redux";
import { store } from "redux/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  // </React.StrictMode>
);
