import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

const selectHelpers = (state: RootState) => state.helpers;

export const selectCurrentForm = createSelector(
  [selectHelpers],
  (helpers) => helpers.currentForm,
);

export const selectFormType = createSelector(
  [selectHelpers],
  (helpers) => helpers.formType,
);

export const selectPopup = createSelector(
  [selectHelpers],
  (helpers) => helpers.popup,
);

export const selectIsFormDisabled = createSelector(
  [selectHelpers],
  (helpers) => helpers.isFormDisabled,
);

export const selectIsCoverFeeSupported = createSelector(
  [selectHelpers],
  (helpers) => helpers.isCoverFeeSupported,
);

export const selectPresValue = createSelector(
  [selectHelpers],
  (helpers) => helpers.presValue,
);

export const selectIsPointsCardSupported = createSelector(
  [selectHelpers],
  (helpers) => helpers.isPointsCardSupported,
);

export const selectPointsCardType = createSelector(
  [selectHelpers],
  (helpers) => helpers.pointsCardType,
);

export const selectInformationalHTML = createSelector(
  [selectHelpers],
  (helpers) => helpers.informationalHTML,
);

export const selectContextualBoxHTML = createSelector(
  [selectHelpers],
  (helpers) => helpers.contextualBoxHTML,
);

export const selectFooterHTML = createSelector(
  [selectHelpers],
  (helpers) => helpers.footerHTML,
);

export const selectMonthlySuggestionText = createSelector(
  [selectHelpers],
  (helpers) => helpers.monthlySuggestionText,
);

export const selectResearcherName = createSelector(
  [selectHelpers],
  (helpers) => helpers.researcherName,
);

export const selectOpportunityId = createSelector(
  [selectHelpers],
  (helpers) => helpers.opportunityId,
);

export const selectAssetsAvailable = createSelector(
  [selectHelpers],
  (helpers) => helpers.assetsAvailable,
);

export const selectHasAgreedToTerms = createSelector(
  [selectHelpers],
  (helpers) => helpers.hasAgreedToTerms,
);

export const selectIsPageError = createSelector(
  [selectHelpers],
  (helpers) => helpers.isPageError,
);

export const selectViewportHeight = createSelector(
  [selectHelpers],
  (helpers) => helpers.viewportHeight,
);

export const selectSession = createSelector(
  [selectHelpers],
  (helpers) => helpers.session,
);

export const selectIsCheckoutCallError = createSelector(
  [selectHelpers],
  (helpers) => helpers.isCheckoutCallError,
);

export const selectAuthenticationToken = createSelector(
  [selectHelpers],
  (helpers) => helpers.authenticationToken,
);
