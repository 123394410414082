import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";
import { getSessionStartTime } from "utils/helper-functions";
import { Helpers, Popup, Session } from "utils/interfaces";

const initialState: Helpers = {
  currentForm: 0,
  formType: "info",
  popup: {
    isActive: false,
    message: "",
    isError: false,
    isLoading: false,
    isSession: false,
  },
  isCoverFeeSupported: true,
  presValue: "",
  isFormDisabled: false,
  isPointsCardSupported: false,
  pointsCardType: "",
  contextualBoxHTML: { en: "", fr: "" },
  informationalHTML: { en: "", fr: "" },
  footerHTML: { en: "", fr: "" },
  monthlySuggestionText: { en: "", fr: "" },
  researcherName: process.env.REACT_APP_DEFAULT_RESEARCHER_NAME,
  opportunityId: 0,
  assetsAvailable: true,
  hasAgreedToTerms: false,
  isPageError: false,
  viewportHeight: window.innerHeight,
  session: {
    sessionMilliseconds: +process.env.REACT_APP_SESSION_TIMEOUT_MILLISECONDS,
    isActive: true,
    sessionID: nanoid(),
    startDate: getSessionStartTime(),
  },
  isCheckoutCallError: false,
  authenticationToken: undefined,
};

const formSlice = createSlice({
  name: "helpers",
  initialState,
  reducers: {
    updateCurrentForm(state, action: PayloadAction<number>) {
      state.currentForm = action.payload;
    },
    updateFormType(state, action: PayloadAction<string>) {
      state.formType = action.payload;
    },
    updatePopup(state, action: PayloadAction<Popup>) {
      state.popup = action.payload;
    },
    updateIsFormDisabled(state, action: PayloadAction<boolean>) {
      state.isFormDisabled = action.payload;
    },
    updateIsPointsCardSupported(state, action: PayloadAction<boolean>) {
      state.isPointsCardSupported = action.payload;
    },
    updatePointsCardType(state, action: PayloadAction<string>) {
      state.pointsCardType = action.payload;
    },
    updateIsCoverFeeSupported(state, action: PayloadAction<boolean>) {
      state.isCoverFeeSupported = action.payload;
    },
    updatePresValue(state, action: PayloadAction<string>) {
      state.presValue = action.payload;
    },
    updateContextualBoxHTML(
      state,
      action: PayloadAction<{ en: string; fr: string }>,
    ) {
      state.contextualBoxHTML = action.payload;
    },
    updateInformationalHTML(
      state,
      action: PayloadAction<{ en: string; fr: string }>,
    ) {
      state.informationalHTML = action.payload;
    },
    updateFooterHTML(state, action: PayloadAction<{ en: string; fr: string }>) {
      state.footerHTML = action.payload;
    },
    updateMonthlySuggestionText(
      state,
      action: PayloadAction<{ en: string; fr: string }>,
    ) {
      state.monthlySuggestionText = action.payload;
    },
    updateResearcherName(state, action: PayloadAction<string>) {
      state.researcherName = action.payload;
    },
    updateOpportunityId(state, action: PayloadAction<number>) {
      state.opportunityId = action.payload;
    },
    updateAssetsAvailable(state, action: PayloadAction<boolean>) {
      state.assetsAvailable = action.payload;
    },
    updateHasAgreedToTerms(state, action: PayloadAction<boolean>) {
      state.hasAgreedToTerms = action.payload;
    },
    updateIsPageError(state, action: PayloadAction<boolean>) {
      state.isPageError = action.payload;
    },
    updateViewportHeight(state, action: PayloadAction<number>) {
      state.viewportHeight = action.payload;
    },
    updateSession(state, action: PayloadAction<Session>) {
      state.session = action.payload;
    },
    updateIsCheckoutCallError(state, action: PayloadAction<boolean>) {
      state.isCheckoutCallError = action.payload;
    },
    updateAuthenticationToken(state, action: PayloadAction<string>) {
      state.authenticationToken = action.payload;
    },
  },
});

export const {
  updateCurrentForm,
  updatePopup,
  updateIsFormDisabled,
  updateIsPointsCardSupported,
  updatePointsCardType,
  updateContextualBoxHTML,
  updateInformationalHTML,
  updateFooterHTML,
  updateMonthlySuggestionText,
  updateIsCoverFeeSupported,
  updatePresValue,
  updateFormType,
  updateResearcherName,
  updateOpportunityId,
  updateAssetsAvailable,
  updateHasAgreedToTerms,
  updateIsPageError,
  updateViewportHeight,
  updateSession,
  updateIsCheckoutCallError,
  updateAuthenticationToken,
} = formSlice.actions;

export default formSlice.reducer;
